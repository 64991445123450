import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Arrow from "../icons/arrow";

const BlogRollTemplate = (props) => {
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <>
      <div className={"grid gap-8 md:gap-14 md:grid-cols-2 mb-8 md:mb-20"}>
        {posts.slice(0, 2).map(({ node: post }) => (
          <Link
            key={post.id}
            className={"block"}
            to={post.fields.slug}
            rel="noreferrer"
            target="_blank"
          >
            <div className={""}>
              <GatsbyImage
                image={getImage(post.frontmatter.featuredimage)}
                className={"w-full h-72 md:h-[380px]"}
              />
            </div>
            <strong className="block pt-4 my-6 text-xl">
              {post.frontmatter.title}
            </strong>
            <div className="mb-6 md:min-h-[90px]">
              {post.frontmatter.description.length > 120
                ? `${post.frontmatter.description.substring(0, 120)}...`
                : post.frontmatter.description}
            </div>
            <div className="flex items-center">
              <span className={`inline-block mr-4`}>Read more</span>
              <Arrow color="#2D5FF2" />
            </div>
          </Link>
        ))}
      </div>

      <div className={"grid gap-8 md:gap-x-8 md:gap-y-16 md:grid-cols-3"}>
        {posts.slice(2).map(({ node: post }) => (
          <Link
            key={post.id}
            className={"block"}
            to={post.fields.slug}
            rel="noreferrer"
            target="_blank"
          >
            <div className={""}>
              <GatsbyImage
                image={getImage(post.frontmatter.featuredimage)}
                className={"w-full h-72md:h-[300px] lg:h-[450px] mb-6"}
              />
            </div>
            <div className="mb-6 md:min-h-[100px]">
              {post.frontmatter.description.length > 120
                ? `${post.frontmatter.description.substring(0, 120)}...`
                : post.frontmatter.description}
            </div>
            <Arrow color="#404040" />
          </Link>
        ))}
      </div>
    </>
  );
};


BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  description
                  date(formatString: "MMMM DD, YYYY")
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
