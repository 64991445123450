import * as React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/Seo";
import BlogRoll from "../../components/BlogRoll";
import { StaticImage } from "gatsby-plugin-image";
export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="Blog" />
        <div className={`h-[250px] md:h-[600px] relative mb-12 md:mb-20`}>
          <StaticImage
            src="../../images/hero-blogs.jpg"
            alt="FindurFit Blog"
            layout="fullWidth"
            className={`w-full h-full`}
          />
          <div className={`absolute bottom-0 pb-4 md:pb-16 w-full`}>
            <div className={`mx-auto max-w-screen-xl w-full px-6`}>
              <div className={`max-w-4xl`}>
                <h1 className="text-white lg:pr-12">Blog</h1>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="w-full max-w-screen-xl px-6 mx-auto">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
